
import { Component, Vue } from 'vue-property-decorator';
import { AdminService } from '@/services';

// Types
import { IBrands } from '@/types/admin.types';

@Component({
  components: {}
})
export default class AdminBrands extends Vue {
  allBrands: IBrands[] = [];

  editedIndex = -1;
  dialog = false;
  isUpdateBrand = false;

  headers = [
    { text: 'NAME', value: 'name' },
    { text: 'PRIORITY', value: 'priority' },
    { text: 'ACTIONS', value: 'actions', sortable: false }
  ];

  defaultData = {
    id: '',
    createdAt: '',
    lastSyncAt: '',
    logoUrl: '',
    name: '',
    priority: 0,
    updatedAt: '',
    websiteUrl: ''
  };

  editedItem: IBrands = this.defaultData;
  defaultItem: IBrands = this.defaultData;

  editItem(item: IBrands): void {
    this.editedIndex = this.allBrands.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  async save(): Promise<void> {
    this.isUpdateBrand = true;
    let formData = {
      priority: this.editedItem.priority
    };
    await AdminService.updateBrand(formData, this.editedItem.id);
    this.isUpdateBrand = false;
    this.close();
    this.getAdminBrands();
  }

  async getAdminBrands(): Promise<void> {
    try {
      this.allBrands = await AdminService.fetchBrands();
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async created() {
    this.getAdminBrands();
  }
}
